import { Controller } from  "@hotwired/stimulus"
export default class extends Controller {
  static targets = ["chart"];

  connect() {
    const data = JSON.parse(this.chartTarget.dataset.value);    
    const total = data.reduce((sum, item) => sum + item.value, 0);

    const dataWithPercentages = data.map(item => ({
      label: `${item.label} (${((item.value / total) * 100).toFixed(2)}%)`,
      value: item.value
    }));

    new Morris.Donut({
      element: this.element,
      data: dataWithPercentages,
      colors: ['#0b62a4', '#7a92a3', '#4da74d', '#afd8f8'],
      resize: true
    });

    const currParams = new URLSearchParams(window.location.search)
    const dates = currParams.get('dates')
    const machineryIds = currParams.getAll('machinery_ids[]')
    console.log('test machinery_ids: ')
    console.log(machineryIds)
    document.querySelectorAll('path[fill="#0b62a4"]')[0]?.addEventListener('click', () => this.redirectToReport("reports/quality_reports", dates, machineryIds))
    document.querySelectorAll('path[fill="#7a92a3"]')[0]?.addEventListener('click', () => this.redirectToReport("reports/halt_reasons", dates))
    document.querySelectorAll('path[fill="#4da74d"]')[0]?.addEventListener('click', () => this.redirectToReport("machinery_times", dates))    
  }

  redirectToReport(reportType, dates, machineryIds = []) {
    let url = `/${reportType}`
    const params = new URLSearchParams()

    if(dates) params.append("dates", dates)
    
    if(reportType === "reports/quality_reports" && machineryIds.length > 0) {
      machineryIds.forEach(id => params.append('machinery_ids[]', id))
    }

    if(params.toString()) {
      url += `?${params.toString()}`
    }

    window.location.href = url
  }
}