import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['tableBody', 'input']

    connect(){}

    preventSubmit(event) {
        if (event.key === "Enter") { event.preventDefault(); }
    }

    processBarcode(event) {
        if (event.key === "Enter") {
            const barcode = this.inputTarget.value.trim();
            this.sendBarcodeToScan(barcode);
            this.inputTarget.value = '';
            //console.log(barcode);
        }
    }

    sendBarcodeToScan(barcode){
        fetch('/drying_carts/scan_code', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
            },
            body: JSON.stringify( { cart_barcode: barcode } )
        })
        .then(response => response.json())
        .then(data =>{
            if(data.valid){
                this.setCartValue(data.cart_name, data.cart_id);
            }else{
                swal('Código no válido', data.message, 'error');
            }
        })
        .catch(error => {
            console.log("Error al enviar el código de barras:", error);
        });
    }

    setCartValue(name, id){
        if($(this.tableBodyTarget).find('tr').length == 1){
            swal('Código no válido', "No se permite más de un carro de secado", 'error');
            return;
        }

        const newRow = document.createElement('tr');
        console.log('Fila: ', $(this.tableBodyTarget).find('tr').length)
        for (let i = 0; i < 2; i++) {
            const cell = document.createElement('td');
            cell.style.width = '50%';
            cell.classList.add('text-center');

            if(i === 0){
                cell.textContent = name;
            }else{
                const del_btn = document.createElement('button');
                del_btn.textContent = 'Remover';
                del_btn.classList.add('btn', 'btn-danger');

                del_btn.addEventListener('click', () => {
                    $('#drying_cart_input').val('');
                    // del table row
                    newRow.remove();
                });
                cell.appendChild(del_btn);
            }
            newRow.appendChild(cell);
        }
        this.tableBodyTarget.appendChild(newRow);

        $('#drying_cart_input').val(id);
    }
}